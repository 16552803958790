import React, { useEffect, useState } from 'react'

import Container from 'atoms/Container'
import ImageCaptionCardV2 from 'molecules/v1/ImageCaptionCardV2'
import Heading from 'atoms/Heading'
import HorizontalSliderV2 from 'molecules/HorizontalSliderV2'
import { TREATMENT_SLIDER_HOMEPAGE_BREAKPOINTS, COMMON_HEALTH_CONCERNS } from 'constant/patientHomepage'
import Text from 'atoms/Text'
import IconRightArrow from 'atoms/SvgIcons/IconRightArrow.svg'
import LinkAnchor from 'atoms/LinkAnchor'
import { getBannerList } from 'services/medicine.service'
import { getBannerUrl } from 'core/homePage'
import { useRouter } from 'next/router'
const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL;

const CommonHealthConcerns = ({ setIsOpenDiseasesModal, concernAreaList, setConcernArea, auth }) => {
    const [commonHealthBanner, setCommonHealthBanner] = useState([])
    const router = useRouter()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const bannerResponse = await getBannerList(13, auth?.user?.id, 'D2C', auth?.token);
                if (bannerResponse?.status) {
                    let sliderList = bannerResponse?.entity?.map((item) => {
                        return {
                            id: item?.id, categoryId: item?.id, categoryName: item?.name, image: `${bucketUrl}${item?.home_image}`, isHideCategoryName: true,
                            onBannerClick: () => {
                                const url = getBannerUrl({
                                    linkingType: item?.linking_type,
                                    bannerLinkingId: item?.banner_linking_id,
                                    pageUrl: item?.page_url,
                                    bannerType: item?.banner_type,
                                });
                                router.push(url);
                            },
                        }
                    })
                    setCommonHealthBanner(sliderList)
                }
            } catch (error) {
                console.error("Error fetching banners:", error);
            }
        };
        fetchData();
    }, []);


    return (
        <>{
            commonHealthBanner.length >0 &&
            <div className='pb-0'>
                <Container type='static' className=''>
                <Heading className="font-Montserrat text-18-28 md:text-18-20 text-gray-900 md:mb-0 text-center mt-5 " type='h2' weight={600}>Common Health Concerns</Heading>
                    <HorizontalSliderV2
                        className="w-auto h-full cursor-pointer"
                        breakpoints={TREATMENT_SLIDER_HOMEPAGE_BREAKPOINTS}
                        title="Featured Posts"
                        navigationElement={"common-health-concern"}
                        autoplayDelay={2000}
                        spaceBetween={10}
                        sliderList={commonHealthBanner?.map(item => (
                            <div className='border-1 border-gray-200 rounded-lg shadow-sm h-40 w-40 md:ml-2 ml-2  '>
                                <div className='flex flex-col items-center justify-center w-full h-3/5 mt-1'>
                                    <img src={item.image} alt={item.categoryName} className='w-full h-full object-contain' onClick={item?.onBannerClick} />
                                </div>                      
                                <Text fontWeight={600} className=" p-2 text-gray-600 font-Open-Sans text-12-16">{item.categoryName}</Text>
                                <LinkAnchor href={''} dataAutomation='-section-homepage' className='pl-2 text-primary1-500 font-Open-Sans text-[12px] inline-block font-semibold' onClick={item?.onBannerClick}>
                                    <span className='whitespace-nowrap'>Consult Now</span> <IconRightArrow className='inline-block fill-none ml-1.5 text-primary1-500 w-3 h-3' />
                                </LinkAnchor>
                            </div>
                        ))}
                    />
                </Container>
            </div>}
        </>         
    )
}

export default CommonHealthConcerns