import Card from "atoms/Card";
import ImageNext from "atoms/ImageNext";
import LinkAnchor from "atoms/LinkAnchor";
import Text from "atoms/Text";
import IconAddMeeting from "atoms/SvgIcons/HompagePatientIcons/IconAddMeeting.svg";
import IconVideo from "atoms/SvgIcons/IconVideo.svg";
import IconStar from "atoms/SvgIcons/HompagePatientIcons/IconStar.svg";
import { getAuth } from "services/identity.service";
const moment = require('moment');
import appointment_flow from "../assets/mo_engage/appointment_flow.json"
import { captureEvent } from 'services/analytics.service';

const DoctorsSummeryCardV2 = ({ className, image, fullName, city, degree, experience, specializations, buttonText, handleAppointment, linkHref, consultationFee, rating, isShowVideoIcon = false, nextAvailableSlots, isCurated = false, pageName= '', doctorId=null}) => {
    const auth = getAuth();
    const isConsultationAllowed = auth?.user?.userType == 1 || auth?.user?.userType == undefined;
    const eventCategory = pageName;
    const eventName = appointment_flow?.entity?.Request_Appointment_Click?.event_name;
    return (
        <Card className={`${className} cursor-pointer hover:shadow-extraLight transition-all duration-300 card--regular relative flex flex-col h-full p-3 rounded-md `}>
            <LinkAnchor className='flex-grow' href={linkHref}>
                <div className="flex flex-col w-full h-full">
                    <div className="relative flex items-center justify-between w-full">
                        <div className="flex items-center w-full">
                            <div className="relative min-w-[80px] h-20 rounded-full bg-gray-100 z-0">
                                <ImageNext
                                    className="w-full h-full object-cover rounded-full"
                                    src={image}
                                    alt={fullName}
                                    layout="fill"
                                    defaultImageFor="avatar"
                                />
                                {isConsultationAllowed && isCurated && nextAvailableSlots?.length > 0 && isShowVideoIcon ?
                                    <div className="absolute bottom-1 right-1 flex items-center justify-center h-7 w-7 rounded-full bg-blue-500 text-white overflow-x-visible">
                                        <IconAddMeeting className="h-7" />
                                    </div> : ''}
                            </div>

                            <div className="ml-4 flex-grow w-full">
                                <div className="flex justify-between">
                                    <Text fontWeight={600} className="text-14-16 font-Open-Sans font-semibold text-gray-900 line-clamp-1 pb-1">{fullName || ""}</Text>
                                    {isConsultationAllowed ?
                                        <div className="flex justify-end mr-0">
                                            <div className="flex gap-1">
                                                <IconStar className="col-end-5 col-span-1 text-yellow-400 w-4 h-4" />
                                                <Text className="col-end-7 col-span-2 text-14-16 font-Open-Sans font-semibold text-gray-900">{((!rating || rating < 1)) ? 'New' : (rating) + '/5'}</Text>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                                <Text fontWeight={400} className="text-12-16 max-w-170 text-gray-600 line-clamp-1">
                                    {Array.isArray(specializations) ? specializations.join(', ') : specializations}
                                </Text>                                <Text className="line-clamp-1 text-12-16 text-gray-600 pb-1">{experience || ""}</Text>
                                <Text className="text-xm max-w-170 text-12-16 text-gray-600 line-clamp-1">{degree || "degree"}</Text>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className="flex justify-between items-center border border-y-1 border-x-0 mt-2">
                            <Text className="text-12-16 mt-2 mb-2 font-Open-Sans line-clamp-1 text-gray-900">{city || ""}</Text>
                            {(isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length > 0) &&
                                <Text className="text-gray-900 text-12-16 mr-5">
                                    {consultationFee ? `₹${consultationFee} Consultation Fee` : 'FREE Consultation'}
                                </Text>}
                        </div>
                        {isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length >= 1 &&
                            <div className="text-gray-600 mt-2">
                                <Text type="label" className="text-10-14 font-Open-Sans text-primary2-800">NEXT AVAILABLE AT</Text>
                                <div className="flex justify-between">
                                    <div className="flex items-center">
                                        <IconVideo className="w-3 h-3 mr-2" />
                                    {nextAvailableSlots[0]?.label &&  <Text className='text-12-16 text-gray-600 font-Open-Sans'>{nextAvailableSlots[0]?.label}, {moment(nextAvailableSlots[0].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>}
                                    </div>
                                    {nextAvailableSlots[1]?.label &&  <div className="flex items-center mr-2">
                                        <IconVideo className="w-3 h-3 mr-2" />
                                       <Text className='text-12-16 text-gray-600 font-Open-Sans'>{nextAvailableSlots[1]?.label}, {moment(nextAvailableSlots[1].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>
                                    </div>}
                                </div>
                            </div>}
                        {
                            (isConsultationAllowed && (isCurated == 0 || nextAvailableSlots?.length == 0)) && <Text className='w-3/4 text-center mx-auto py-6 text-primary1-900 text-xs'>{`${fullName} is not available for online consultation at the moment.`}</Text>
                        }
                    </div>
                    {isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length >= 1 && <div className={`w-full flex justify-center mt-4`}>
                        <button size='extraSmall_v2' onClick={()=>captureEvent(eventCategory, eventName, '', {doctor_id: doctorId, doctor_name: fullName, action: 'Click'})} className='w-50 h-[32px] text-sm button button-Primary rounded-sm-0.5 font-normal'>
                            {buttonText || "Book Video Consultation"}
                        </button>
                    </div>}
                </div>
            </LinkAnchor>
        </Card>



    );
};

export default DoctorsSummeryCardV2;
