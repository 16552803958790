export const breakpoint = {
    0: {
        slidesPerView: 1.1,
        slidesPerGroup: 1,
    },
    360: {
        slidesPerView: 1.1,
        slidesPerGroup: 1,
    },
    640: {
        slidesPerView: 2.1,
        slidesPerGroup: 1,
    },
    768: {
        slidesPerView: 4.2,
        slidesPerGroup: 3,
    },
    1280: {
        slidesPerView: 6.1,
        slidesPerGroup: 4,
    },
}

export const cardBreakpoint = {
    360: {
        slidesPerView: 1,
        slidesPerGroup: 1,
    },
    640: {
        slidesPerView: 2,
        slidesPerGroup: 1,
    },
    768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
    },
    1280: {
        slidesPerView: 3,
        slidesPerGroup: 2,
    },
}

export const blogDisplayBreakpoint = {
    0: {
        slidesPerView: 2.2,
    },

    640: {
        slidesPerView: 3.5,
    },

    768: {
        slidesPerView: 4,
    },

    1024: {
        slidesPerView: 5,
    },

    1280: {
        slidesPerView:5,
    }
};

export const dealsbreakpoint = {
    0: {
        slidesPerView: 1.2,
        slidesPerGroup: 1,
    },
    500: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
    },
    640: {
        slidesPerView: 2.5,
        slidesPerGroup: 1,
    },
    768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
    },
    1280: {
        slidesPerView: 3,
        slidesPerGroup: 1,
    },

}
export const offerBreakPoint1 = {
    0: {
        slidesPerView: 1.2,
        slidesPerGroup: 1,
    },
    500: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
    },
    640: {
        slidesPerView: 2.5,
        slidesPerGroup: 1,
    },
    768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
    },
    1280: {
        slidesPerView: 2,
        slidesPerGroup: 2,
    },

}


export const offerBreakPoint2 = {
    0: {
        slidesPerView: 1.2,
        slidesPerGroup: 1,
    },
    500: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
    },
    640: {
        slidesPerView: 2.5,
        slidesPerGroup: 1,
    },
    768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
    },
    1280: {
        slidesPerView: 3,
        slidesPerGroup: 3,
    },

}

export const offerBreakPoint3 = {
    0: {
        slidesPerView: 2.6,
        slidesPerGroup: 2.1,
    },
    500: {
        slidesPerView: 3.2,
        slidesPerGroup: 2.5,
    },
    640: {
        slidesPerView: 4,
        slidesPerGroup: 4,
    },
    768: {
        slidesPerView: 4.5,
        slidesPerGroup: 4.5,
    },
    1280: {
        slidesPerView: 6.1,
        slidesPerGroup: 6,
    },

}

export const offerSectionBreakpoints = {
    0: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
    },
    500: {
        slidesPerView: 3.5,
        slidesPerGroup: 1,
    },
    640: {
        slidesPerView: 4.5,
        slidesPerGroup: 1,
    },
    768: {
        slidesPerView: 5,
        slidesPerGroup: 3,
    },
    1280: {
        slidesPerView: 6,
        slidesPerGroup: 2,
    },

}

export const homeSectionBreak = {
    0: {
        slidesPerView: 2.2,
    },

    640: {
        slidesPerView: 3.5,
    },

    768: {
        slidesPerView: 4,
    },

    1024: {
        slidesPerView: 6,
    },

    1280: {
        slidesPerView:6,
    }
}

export const buyMedicineBreak = {
    0: {
        slidesPerView: 1.1,
    },

    640: {
        slidesPerView: 2.1,
    },

    768: {
        slidesPerView: 3.1,
    },

    1024: {
        slidesPerView: 4,
    },

    1280: {
        slidesPerView: 4,
    }

}