const s3BucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL

// banner
export const NIROG_PARTNER_BANNER_DESCRIPTION = 'Welcome to NirogStreet’s Partnership Program where we bring to you Nirog Partner and Nirog Partner +. These are initiatives that empower Ayurveda Doctors by providing them access to facilities like wide range of medicines, easy margins* &  domestic and international shipping to patients and video consultations.';
export const NIROG_PARTNER_BANNER_IMG = '/images/npBannerWeb.webp';
export const NIROG_PARTNER_BANNER_IMG_MOB = '/images/npBannerMob.png';


export const offersList = [
    { id: 1, src: '/images/free-shipping.svg', description: 'Free delivery of medicines to your patients across 2000+ cities' },
    { id: 2, src: '/images/discount.svg', description: 'Get more margins on medicines that you sell to your patients through us' },
    { id: 3, key: 'medicines', src: '/images/medicines.svg', description: 'Access to 5000+ medicines without spending a rupee to maintaining stock at your clinic' },
    { id: 4, src: '/images/telemedicine.svg', description: 'Get patients everyday from online appointment booked on the website' },
    { id: 4, src: '/images/crm 1.svg', description: 'Efficient Patient Management Software- From appointments to managing medical histories and placing orders' },
    { id: 5, src: '/images/digital-marketing.svg', description: 'Developing a Wide Digital Success - get free content, creatives, Videos to share on your social media pages' },
    { id: 6, src: '/images/browser.svg', description: 'Get your digital webpage at zero cost' },
    { id: 6, src: '/images/manager.svg', description: 'Dedicated account manager- Personalizes guidance to assist you every step of the way' },
]

export const NP_ADVANTAGE = [
    { id: 1, src: '/images/growth.svg', description: 'Earn more money every day from different sources of income' },
    { id: 2, src: '/images/filter.svg', description: 'Get patient appointment requests based on your expertise' },
    { id: 3, src: '/images/good-job.svg', description: 'Increase patient satisfaction by delivering medicine to their doorstep for free' },
    { id: 4, src: '/images/personal-data.svg', description: 'Easily access patient records to save time and reduce administrative work' },
    { id: 4, src: '/images/relaxation.svg', description: 'Say goodbye to the challenges of storing and dealing with expired stock' },
    { id: 5, src: '/images/webinar.svg', description: 'Increase your online presence, attract more patients, and improve your professional reputation' },
]

export const NP_OFFERS_HEADING = 'Nirog Partner is an exclusive program that offers';
export const NP_ADVANTAGE_HEADING = 'Becoming a Nirog Partner has many advantages for Vaidyas';
export const NP_STEPS_HEADING = 'How to Become a Nirog Partner';
export const NP_COMPARISON_HEADING = 'Comparison: Nirog Partner & Nirog Partner +';
export const NP_DOCTOR_HEADING = 'Trusted by Experienced Doctors';
export const NP_FAQ_HEADING = 'FAQs';
export const NP_EMAIL = 'abhishek.kishore@nirogstreet.in';


export const NP_STEPS = [
    { id: 1, src: '/images/register.svg', description: 'Register your account' },
    { id: 2, src: '/images/medical-record.svg', description: 'Verify your account' },
    { id: 3, src: '/images/deal-white.svg', description: 'Become a partner & start ordering' },
    { id: 4, src: '/images/file-upload.svg', description: 'Upload your Bank Details' },
    { id: 5, src: '/images/earning.svg', description: 'Receive your earnings'},
]

export const NP_TESTIMONIAL_LIST = [
    { id: 1, name: 'Dr. Mahesh Sharma', userType: 'Doctor', avatar: '/doctors/Dr. Mahesh Sharma.jpeg', description: `Online consultations are made even easier with NirogStreet's Partnership Program, which has potentially increased the number of patients and generated additional income.` },
    { id: 2, name: 'Dr. Vishnu Panchal', userType: 'Doctor', avatar: '/doctors/DR. Vishnu Panchal.jpeg', description: `Nirogstreet's Partnership Program has helped me grow with valuable services to my patients and increased my earnings. I have been able to reach out to more people with confidence.` },
    { id: 3, name: 'Dr. Stuti', userType: 'Doctor', avatar: '/doctors/Dr. Stuti.jpeg', description: `Nirogstreet's partnership has helped me to open my e- clinic and it has also provided me the ease of getting medicines with no cost delivery.` },
    { id: 4, name: 'Dr. Priyanka', userType: 'Doctor', avatar: '/doctors/Dr Priyanka.jpeg', description: `NirogStreet's partnership program has provided me with technical support and technology-friendly marketing and promotional support for my clinic, due to which I have got a chance to interact with a lot of patients and have good visibility in the healthcare society.` },
]

export const NP_DOCTOR_LIST = [
    { id: 1, doctorName: 'Avinash Kr', doctorImage: `${s3BucketUrl}images/profiles/1634998913.jpg`, experience: 'Md (ayu), B.A.M.S.', doctorLink: '/' },
    { id: 2, doctorName: 'Anmol Kr', doctorImage: `${s3BucketUrl}images/profiles/1634998913.jpg`, experience: 'Md (ayu), B.A.M.S.', doctorLink: '/' },
    { id: 3, doctorName: 'Ankur PD', doctorImage: `${s3BucketUrl}images/profiles/1634998913.jpg`, experience: 'Md (ayu), B.A.M.S.', doctorLink: '/' },
    { id: 4, doctorName: 'Ankur PD', doctorImage: `${s3BucketUrl}images/profiles/1634998913.jpg`, experience: 'Md (ayu), B.A.M.S.', doctorLink: '/' },
]

export const NP_FAQ_LIST = [
    { id: 1, title: `Does a Doctor get patients leads also`, description: `Yes, as a Nirog Partner Plus member Doctors do get patient's leads from NirogStreet's side` },
    { id: 2, title: `How does the payout work`, description: `NS makes a payout against delivered orders every week to the Partner Doctors` },
    { id: 3, title: `Does NirogStreet charge any consultation fee from the patient? `, description: `No, NirogStreet does not charge any consultation fee from the patient. ` },
    { id: 4, title: `How long does it take for medicines to be delivered`, description: `It takes 5-7 working days for medicines to be delivered` },
]

export const NP_SCALE_METRICS_LIST = [
    { id: 1, key: 'medicines', src: '/images/medicines-v2.svg', },
    { id: 2, key: 'doctors', src: '/images/doctor.svg', },
    { id: 3, key: 'orders', src: '/images/checkout.svg', },
    { id: 4, key: 'clinics', src: '/images/hospital.svg', },
]


export const NP_LOGGED_USER = {
    ADVANTAGE_HEADING: 'Benefits of becoming a NirogPartner',
    offersList: [
        { id: 1, src: '/images/free-shipping.svg', description: 'Free delivery of medicines to your patients across 2000+ cities' },
        { id: 2, src: '/images/discount.svg', description: 'Get more margins on medicines sold to your patients through us' },
        { id: 3, key: 'medicines', src: '/images/medicines.svg', description: 'Access to 5000+ medicines without spending money on stock maintenance' },
        { id: 4, src: '/images/telemedicine.svg', description: 'Get patients from online appointments booked through the website' },
        { id: 4, src: '/images/crm 1.svg', description: 'Efficient Patient Management Software- Make appointments, Manage medical histories & place orders' },
        { id: 5, src: '/images/digital-marketing.svg', description: 'Develop a Wide Digital Success - Get free content to share on social media' },
        { id: 6, src: '/images/browser.svg', description: 'Get your digital webpage at no cost' },
        { id: 6, src: '/images/manager.svg', description: 'Dedicated account manager - Personalised guidance to assist you every step of the way' },
    ],
    FAQ_LIST: [
        { id: 1, title: `How will I get paid for consultations?`, description: `The consultation fee paid by patients is transferred directly to your account after a successful consultation. You'll also earn margins on any medicine orders placed during the consultation.` },
        { id: 2, title: ` When will I receive my earnings?`, description: `Your earnings, including consultation fees and medicine margins, are paid out according to NirogStreet's billing cycle.` },
        { id: 3, title: `What happens if I miss a consultation?`, description: ` If you don't join a consultation, the appointment will be marked as cancelled or rescheduled. In case of cancellation, you won't receive any fee for that appointment.` },
        { id: 4, title: `How do I manage appointments and prescriptions?`, description: `After a consultation, remember to create a prescription for your patient, place any medicine orders if needed, and mark the appointment as "Consulted" in the NirogStreet system.` },
        { id: 5, title: `How do I offer discounts on medicines to my patients?`, description: `While creating an order for your patient, you can choose the discount that you want to offer on medicines.` },
        { id: 6, title: ` Where can I find more information about the cancellation policy?`, description: ` For details on appointment cancellations and fees, please refer to NirogStreet's <a href="/cancellation-policy" target="_blank">Cancellation Policy</a>, which you can access on their website or within the program guide after registering.` },
        { id: 7, title: `Can I adjust my consultation settings later?`, description: `Absolutely! You can change your consultation fees and other settings anytime under "My Profile" within the NirogStreet platform.` },
    ]
}

export const NP_COMPARISON_CONTENT = [
    { name: 'Digital Profile highlighting your information & experience', nirogPartner: true, nirogPartnerPlus: true },
    { name: 'Access to the largest selection of medicines at discounted prices', nirogPartner: true, nirogPartnerPlus: true },
    { name: 'Place order for your patients', nirogPartner: true, nirogPartnerPlus: true },
    { name: 'Doorstep delivery of medicines all across India', nirogPartner: true, nirogPartnerPlus: true },
    { name: 'Real-time order tracking', nirogPartner: true, nirogPartnerPlus: true },
    { name: 'Ayurveda specific Patient Management System', nirogPartner: true, nirogPartnerPlus: true },
    { name: 'Earn your own retail margins on orders', nirogPartner: true, nirogPartnerPlus: true },
    { name: 'International shipping of medicines (Coming Soon)', nirogPartner: false, nirogPartnerPlus: true },
    { name: 'Promotional content for your social media pages', nirogPartner: false, nirogPartnerPlus: true },
    { name: 'Marketing promotions of your Digital Profiles to increase chances of patient enquiries and consultation requests', nirogPartner: false, nirogPartnerPlus: true },
    { name: 'Profile promotions on Nirogstreet’s social media pages', nirogPartner: false, nirogPartnerPlus: true },
    { name: 'Increased visibility of profile on NirogStreet platform', nirogPartner: false, nirogPartnerPlus: true },
    { name: 'Host your webinars on NirogStreet', nirogPartner: false, nirogPartnerPlus: true },
    { name: 'One-time review and update/creation of your social media pages on Facebook and Instagram', nirogPartner: false, nirogPartnerPlus: true },
];
export const NP_DESCRIPTION = 'Nirog Partner Program empowers Ayurveda Doctors to manage patient profiles, digital prescriptions, access an extensive selection of medicines, seamlessly get them shipped directly to patients across the country and earn margins on every order delivered.';
export const NP_BENEFITS = [
    "No need to stock medicines at your clinic, access to the largest selection of authentic Ayurveda medicines in India!",
    "Order medicines at discounted prices and decide your own margin (your earnings) when you sell these to your patients",
    "Transparent margin payments directly to your chosen bank account or UPI **",
    "Hassle-free and Direct shipping to patients across India",
    "Track orders in real-time",
    "Get your Digital profile created",
    "Integrated Patient Management System / Vaidya tool  for smooth management of patients and clinics",
];

export const NP_ELIGIBILITY = 'You will need an account on NirogStreet platform and should have completed profile verification. Once your profile settings are updated and verification completed, you will get access to Nirog Partner capabilities.';
export const NP_IMP_POINTERS = '*Medicines are made available to you at discounted rates, by default the price for patients is MRP. You get to keep the difference between this discounted rate and MRP on each order as Your Margin. <br/><br/> ** Margin payout to your account happens on 1st of each month';

export const NP_PLUS_DESCRIPTION = "Nirog Partner+ is a loyalty program that empowers you to enhance your Ayurvedic practice. We'll boost your profile on NirogStreet making it easier for potential patients to find you. Manage consultations seamlessly, and get an opportunity to host webinars to showcase your expertise.";
export const NP_PLUS_BENEFITS = [
    "Order medicines at discounted prices and decide your own margin (your earnings) when you sell these to your patients",
    "Earn consultation fees on successful consultation which will be transferred to your account at the end of each month",
    "Hassle-free and Direct shipping to patients",
    "Track orders in real-time",
    "International shipping is coming soon!",
    "Increased profile visibility through NirogStreet’s digital marketing programs",
    "Transparent margin payments, consultation fees transferred directly to your preferred bank or UPI",
    "Get access to promotional content tailored to your qualifications for personal advertising on your social media pages",
    "Opportunity to host a webinar through our platform",
    "One-time review and update/creation of your social media pages on Facebook and Instagram**",
];
export const NP_PLUS_IMP_POINTERS = 'You need to be a transacting user on our platform with a min. of 2 orders in each of the previous 3 months.';