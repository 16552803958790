import React from 'react'
import Heading from 'atoms/Heading'
import Text from 'atoms/Text'
import Container from 'atoms/Container'
import LinkAnchor from 'atoms/LinkAnchor'

const PractitionerGetStarted = (props) => {
    const { className = '', heading, link, dataAutomation, description, ...rest } = props

    return (
        <div className={`${className} bg-primary1-900 w-full py-6 md:py-7`} {...rest}>
            <Container type='static' className={`px-4 w-full max-w-[1120px] mx-auto`}>
                <Heading className="font-Montserrat text-18-20 text-center font-bold" textColor='text-basic-white'>{heading}</Heading>
                <Text className='md:w-78per mx-auto text-14-20 mt-2 mb-5 text-basic-white text-center font-sans'>
                    {description}
                </Text>
                <LinkAnchor href={link} dataAutomation='get-started-homepage' className='block'>
                    <button class="w-[250px] h-10 mx-auto button button-Primary buttonSize-small rounded-lg text-sm" data-automation="get-started-button">Get Started</button>
                </LinkAnchor>
            </Container>
        </div>
    )
}
export default PractitionerGetStarted
