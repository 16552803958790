import Head from "next/head";

function objectToString(obj) {
    let str = "{";
    let isFirst = true;

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (!isFirst) {
                str += ", ";
            }

            const value = obj[key];
            str += `"${key}": `;

            if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    str += arrayToString(value);
                } else {
                    str += objectToString(value);
                }
            } else if (typeof value === 'string') {
                str += `"${value}"`;
            } else {
                str += value;
            }

            isFirst = false;
        }
    }

    str += "}";
    return str;
}

function arrayToString(arr) {
    let str = "[";
    let isFirst = true;

    for (let i = 0; i < arr.length; i++) {
        if (!isFirst) {
            str += ", ";
        }

        const value = arr[i];

        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                str += arrayToString(value);
            } else {
                str += objectToString(value);
            }
        } else if (typeof value === 'string') {
            str += `"${value}"`;
        } else {
            str += value;
        }

        isFirst = false;
    }

    str += "]";
    return str;
}


const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "NirogStreet",
    "url": "https://nirogstreet.com/",
    "logo": "https://nirogstreet.com/images/NS-logo.png",
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": 9319361976,
        "contactType": "customer service",
        "areaServed": "IN",
        "availableLanguage": ["en", "Hindi"]
    },
    address: {
        "@type": "PostalAddress",
        "streetAddress": "5th Floor, Tower- 4, NBCC Plaza, Pushp Vihar, Saket",
        "addressLocality": "Pushp Vihar Saket",
        "addressRegion": "New Delhi",
        "postalCode": 110017
    },
    sameAs: [
        "https://www.facebook.com/NirogStreet/",
        "https://twitter.com/nirogstreet",
        "https://www.youtube.com/c/NirogStreet",
        "https://www.linkedin.com/company/nirogstreetindia/"
    ]
};

const getBreadSchema = (breadscrumbs) => {
    const schema = {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        itemListElement: breadscrumbs.map((item, index) => ({
            "@type": "ListItem",
            position: (index + 1),
            name: item.label,
            item: {
                "@id": item.route,
                "@type": "Thing"
            }
        }))
    };
    return schema;
};

export default function NirogSeoSchemaHead({ pageTitle, links = [], metaContent = [], schemaEnv = "production", withOrganizationSchema = false, breadcrumb = [], pageSchema }) {
    return (
        <>
            <Head>
                {pageTitle && <title>{pageTitle}</title>}
                {links.length && links.map(item => <link rel={item.rel} href={item.href} />)}
                {metaContent.length && metaContent.map(item => <meta name={item.name} property={item.property} content={item.content} />)}
                {
                    process.env.NODE_ENV === schemaEnv ? <>
                        {withOrganizationSchema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSchema) }} />}
                        {breadcrumb.length && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(getBreadSchema(breadcrumb)) }} />}
                        {pageSchema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(pageSchema) }} />}
                    </> : null
                }
            </Head>
        </>
    );
};