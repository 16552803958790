import React from 'react';
import Text from 'atoms/Text';
import Container from 'atoms/Container';
import useWindowSize from "customHook/useWindowSize";

const HighlightBlueSection = ({ className = '', title, onSellClick, onBtnText }) => {
    const [width] = useWindowSize();

    return (
        <div className={`${className}`}>
            {width > 740 ? (
                <Container type='static' className='px-4 h-10  flex flex-row justify-center'>
                    <Text type='subtitle' className='text-primary1-900 font-semibold text-center sm:flex justify-center items-center flex-wrap text-base md:text-base'>
                        {title}
                    </Text>
                </Container>
            ) : (
                <div className={`${className} marquee flex items-center h-9 w-full overflow-hidden relative text-2xl font-semibold text-primary1-900`}>
                    <div className={`${className} marquee flex items-center h-11 w-full overflow-hidden relative text-2xl font-semibold text-primary1-900`}>
                        <div className="marquee__inner w-fit flex relative" aria-hidden="true">
                            {Array.from({ length: 4 }).map((_, index) => <span className='whitespace-nowrap flex' key={index}>{title}</span>)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HighlightBlueSection;
